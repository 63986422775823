import { Locale } from '@src/asset/util/LocaleContext';
import { UserTimeZoneEnum } from '@src/util/locale/locale.enum';
import uuid from 'react-uuid';
import { atom } from 'recoil';

export const browserLanguageAtom = atom<Locale>({
  key: `browserLanguageAtom-${uuid()}`,
  default: 'ko',
});

export const browserTimezoneAtom = atom<UserTimeZoneEnum>({
  key: `browserTimezoneAtom-${uuid()}`,
  default: UserTimeZoneEnum.Asia_Seoul,
});
