export enum UserTimeZoneEnum {
  Pacific_Midway = 'Pacific/Midway',
  Pacific_Honolulu = 'Pacific/Honolulu',
  America_Juneau = 'America/Juneau',
  America_Boise = 'America/Boise',
  America_Dawson = 'America/Dawson',
  America_Chihuahua = 'America/Chihuahua',
  America_Phoenix = 'America/Phoenix',
  America_Chicago = 'America/Chicago',
  America_Regina = 'America/Regina',
  America_Mexico_City = 'America/Mexico_City',
  America_Belize = 'America/Belize',
  America_Detroit = 'America/Detroit',
  America_Bogota = 'America/Bogota',
  America_Caracas = 'America/Caracas',
  America_Santiago = 'America/Santiago',
  America_St_Johns = 'America/St_Johns',
  America_Sao_Paulo = 'America/Sao_Paulo',
  America_Tijuana = 'America/Tijuana',
  America_Montevideo = 'America/Montevideo',
  America_Argentina_Buenos_Aires = 'America/Argentina/Buenos_Aires',
  America_Godthab = 'America/Godthab',
  America_Los_Angeles = 'America/Los_Angeles',
  Atlantic_Azores = 'Atlantic/Azores',
  Atlantic_Cape_Verde = 'Atlantic/Cape_Verde',
  GMT = 'GMT',
  Europe_London = 'Europe/London',
  Europe_Dublin = 'Europe/Dublin',
  Europe_Lisbon = 'Europe/Lisbon',
  Africa_Casablanca = 'Africa/Casablanca',
  Atlantic_Canary = 'Atlantic/Canary',
  Europe_Belgrade = 'Europe/Belgrade',
  Europe_Sarajevo = 'Europe/Sarajevo',
  Europe_Brussels = 'Europe/Brussels',
  Europe_Amsterdam = 'Europe/Amsterdam',
  Africa_Algiers = 'Africa/Algiers',
  Europe_Bucharest = 'Europe/Bucharest',
  Africa_Cairo = 'Africa/Cairo',
  Europe_Helsinki = 'Europe/Helsinki',
  Europe_Athens = 'Europe/Athens',
  Asia_Jerusalem = 'Asia/Jerusalem',
  Africa_Harare = 'Africa/Harare',
  Europe_Moscow = 'Europe/Moscow',
  Asia_Kuwait = 'Asia/Kuwait',
  Africa_Nairobi = 'Africa/Nairobi',
  Asia_Baghdad = 'Asia/Baghdad',
  Asia_Tehran = 'Asia/Tehran',
  Asia_Dubai = 'Asia/Dubai',
  Asia_Baku = 'Asia/Baku',
  Asia_Kabul = 'Asia/Kabul',
  Asia_Yekaterinburg = 'Asia/Yekaterinburg',
  Asia_Karachi = 'Asia/Karachi',
  Asia_Kolkata = 'Asia/Kolkata',
  Asia_Kathmandu = 'Asia/Kathmandu',
  Asia_Dhaka = 'Asia/Dhaka',
  Asia_Colombo = 'Asia/Colombo',
  Asia_Almaty = 'Asia/Almaty',
  Asia_Rangoon = 'Asia/Rangoon',
  Asia_Bangkok = 'Asia/Bangkok',
  Asia_Krasnoyarsk = 'Asia/Krasnoyarsk',
  Asia_Shanghai = 'Asia/Shanghai',
  Asia_Kuala_Lumpur = 'Asia/Kuala_Lumpur',
  Asia_Taipei = 'Asia/Taipei',
  Australia_Perth = 'Australia/Perth',
  Asia_Irkutsk = 'Asia/Irkutsk',
  Asia_Seoul = 'Asia/Seoul',
  Asia_Tokyo = 'Asia/Tokyo',
  Asia_Yakutsk = 'Asia/Yakutsk',
  Australia_Darwin = 'Australia/Darwin',
  Australia_Adelaide = 'Australia/Adelaide',
  Australia_Sydney = 'Australia/Sydney',
  Australia_Brisbane = 'Australia/Brisbane',
  Australia_Hobart = 'Australia/Hobart',
  Asia_Vladivostok = 'Asia/Vladivostok',
  Pacific_Guam = 'Pacific/Guam',
  Asia_Magadan = 'Asia/Magadan',
  Asia_Kamchatka = 'Asia/Kamchatka',
  Pacific_Fiji = 'Pacific/Fiji',
  Pacific_Auckland = 'Pacific/Auckland',
  Pacific_Tongatapu = 'Pacific/Tongatapu',
}
