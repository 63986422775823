import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import enUSMsg from '@src/locale/en-US.json';
import koMsg from '@src/locale/ko.json';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { browserLanguageAtom, browserTimezoneAtom } from '@src/util/locale/locale.recoil';
import moment from 'moment-timezone';
import { getTimezone } from '@src/asset/util/getTimezoneText';

const Locale = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
`;

export type Locale = 'ko' | 'en-US';
const messageMap = { 'en-US': enUSMsg, ko: koMsg };

const LocaleContext = ({ children }) => {
  const [locale, setLocale] = useRecoilState(browserLanguageAtom);
  const [timeZone, setTimeZone] = useRecoilState(browserTimezoneAtom);
  const [firstRenderLoading, setFirstRenderLoading] = useState(true);
  const [key, setKey] = useState(1);

  const mappedLang = (lang: string): Locale => {
    switch (lang) {
      case 'ko':
      case 'ko-KR':
        return 'ko';
      case 'en':
      case 'en-US':
      case 'en-UK':
        return 'en-US';
      default:
        return 'en-US';
    }
  };

  const getInitLocaleLang = () => {
    const locale =
      localStorage.getItem('locale') ||
      navigator.language ||
      navigator.languages[0] ||
      'ko';

    moment.locale(mappedLang(locale));
    setLocale(mappedLang(locale));
  };

  const getInitTimezone = () => {
    const timeZone = getTimezone();
    moment.tz.setDefault(timeZone);
    setTimeZone(timeZone);
  };

  // 첫 렌더링 시
  useEffect(() => {
    getInitLocaleLang();
    getInitTimezone();
    setFirstRenderLoading(false);
  }, []);

  useEffect(() => {
    if (firstRenderLoading) return;

    localStorage.setItem('timeZone', timeZone);
    localStorage.setItem('locale', locale);
    moment.tz.setDefault(timeZone).locale(locale);

    setKey(prev => prev + 1);
  }, [locale, timeZone]);

  if (firstRenderLoading) return null;

  return (
    <IntlProvider key={key} locale={locale} messages={messageMap[locale]}>
      {children}
    </IntlProvider>
  );
};

export default LocaleContext;
