import * as React from 'react';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import ToastComponent from '@src/components/toast/ToastComponent';
import AntdConfigProvider from '@src/asset/util/AntdConfigProvider';
import LocaleContext from '@src/asset/util/LocaleContext';
import 'moment/locale/ko';
import { SWRConfig, SWRConfiguration } from 'swr';
import { useRouter } from 'next/router';

const SWR_GLOBAL_CONFIG: SWRConfiguration = {
  revalidateOnFocus: false, //창이 포커스되면 자동으로 재검증
};

const theme = {
  colors: {
    primary: '#212121',
  },
};

interface Process {
  env: { [key: string]: string };
}
declare let process: Process;

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
});
const MyApp = props => {
  const { Component, pageProps } = props;
  const Layout = (Component as any).Layout ? (Component as any).Layout : React.Fragment;
  const router = useRouter();

  if (!router.isReady) return null;

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Sentry.ErrorBoundary fallback={'An error has occurred'} />
        <SWRConfig value={SWR_GLOBAL_CONFIG}>
          <RecoilRoot>
            <LocaleContext>
              <AntdConfigProvider>
                <Component {...pageProps} id="app" />
              </AntdConfigProvider>
            </LocaleContext>
            <ToastComponent />
          </RecoilRoot>
        </SWRConfig>
      </Layout>
    </ThemeProvider>
  );
};

export default MyApp;
