import { ConfigProvider } from 'antd';
import React from 'react';
import koLocale from 'antd/lib/locale/ko_KR';
import enLocale from 'antd/lib/locale/en_US';
import { useRecoilValue } from 'recoil';
import { browserLanguageAtom } from '@src/util/locale/locale.recoil';

const AntdConfigProvider = ({ children }) => {
  const locale = useRecoilValue(browserLanguageAtom);

  const locales = {
    ko: koLocale,
    'en-US': enLocale,
  };

  return (
    <ConfigProvider
      locale={locales[locale]}
      getPopupContainer={(trigger: any) => {
        if (trigger) return trigger.parentNode;
        else return document.body;
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdConfigProvider;
