import { UserTimeZoneEnum } from '@src/util/locale/locale.enum';
import moment from 'moment-timezone';

export function getTimezone() {
  const timezone =
    (localStorage.getItem('timeZone') as UserTimeZoneEnum) ||
    Object.values(UserTimeZoneEnum).find(
      timezone => timezone === Intl.DateTimeFormat().resolvedOptions().timeZone
    ) ||
    UserTimeZoneEnum.Asia_Seoul;

  return timezone;
}

const getTime = (n: number) => {
  return String(Math.abs(n)).padStart(2, '0');
};

export default function getTimeZoneText(timeZone?: UserTimeZoneEnum) {
  const timezone = timeZone || getTimezone();

  const currentTime = moment.tz(timezone);
  const offsetMinutes = currentTime.utcOffset();
  const offsetHours = Math.floor(offsetMinutes / 60);
  const hours = getTime(offsetHours);
  const minutes = getTime(offsetMinutes % 60);
  const operator = offsetHours >= 0 ? '+' : '-';

  return `${timezone} (GMT ${operator}${hours}:${minutes})`;
}
