import React, { memo } from 'react';
import styled from 'styled-components';
import Icon from '../icon/Icon';

export const Container = styled.div`
  display: flex;
  height: 0px;
  width: 100%;
  position: relative;
  top: -56px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const ToastWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #393838;
  color: white;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 6px;
  transition: 0.8s;
  opacity: 0;
  z-index: 200000;
  font-weight: 700;
`;

function ToastComponent() {
  return (
    <Container id="toast-root">
      <ToastWrapper id="toast">
        <Icon name="Check18" />
        <span id="toast-message" style={{ marginLeft: 8 }}></span>
      </ToastWrapper>
    </Container>
  );
}

export default memo(ToastComponent);
